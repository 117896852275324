import { FC, useEffect, useMemo, useState } from 'react';
import ContextMenu, { ContextMenuItem } from '../ContextMenu';
import LinkIcon from '../icon/LinkIcon';
import { FileInfo } from './FileInfo';
import { useTranslation } from 'react-i18next';
import StringUtils from '../../../utils/StringUtils';
import { FileUploadType } from './FileuploadType';
import ActivityIcon from '../icon/ActivityIcon';
import FilePreview from '../../form/renderer/FilePreview';
import { FileUtils } from '../../../utils/FileUtils';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { DocumentResponse } from '../../../models/Document';
import { FormType } from '../../../models/FormTypes';

type FileInfoCardProps = {
  file: FileInfo;
  disabled?: boolean;
  deleteOnly?: boolean;
  viewOnly?: boolean;
  onCancel: () => void;
  onFileEdit?: (file: FileInfo) => void;
};

const FileInfoCard: FC<FileInfoCardProps> = (props) => {
  const { t } = useTranslation('common');
  const { file, disabled, deleteOnly, viewOnly, onCancel, onFileEdit } = props;
  const [objectUrl, setObjectUrl] = useState<string>('');
  const { clientForm } = useFormRendererInfo();

  useEffect(() => {
    if (file.type === FileUploadType.File) {
      FileUtils.getFileUrl(file.id).then((url) => setObjectUrl(url ?? ''));
    }
  }, [file.id, file.type]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [objectUrl]);

  const title = useMemo(() => {
    if (clientForm && clientForm.type === FormType.Document && clientForm.attachments) {
      const fileIndex = clientForm.attachments.findIndex((x) => x.fileId === file.id);
      if (fileIndex !== undefined) {
        const documentResponse = clientForm as DocumentResponse;
        return `${StringUtils.makePrefixWithNumber(
          documentResponse.prefix,
          documentResponse.documentNumber,
          documentResponse.templateModuleTranslations,
        )}-${StringUtils.addLeadingZeros(fileIndex + 1)}-${file.name}`;
      }
    }
    return file.name;
  }, [clientForm, file]);

  const disabledStyles = disabled ? 'opacity-50 cursor-not-allowed' : '';

  const menuItems: ContextMenuItem[] = [
    {
      title: t('file-info-card.menu.update'),
      onClick: () => onFileEdit && onFileEdit(file),
      hide: viewOnly || deleteOnly,
    },
    {
      title: t('file-viewer.buttons.download'),
      onClick: () => FileUtils.downloadFile({ id: file.id, name: file.name, addExtension: true }),
      hide: file.type === FileUploadType.Link,
    },
    {
      title: file.type === FileUploadType.Link ? t('file-info-card.menu.delete-link') : t('file-info-card.menu.delete'),
      onClick: onCancel,
      hide: viewOnly,
    },
  ];

  return (
    <div className={`relative flex w-auto ${disabledStyles}`} data-cy="file-info-card">
      <div className="border-grey-5 rounded-dpm-sm mt-2 flex w-full min-w-64 border-2 bg-white p-3">
        <div className="mr-4 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-black">
          {file.type === FileUploadType.Link && <LinkIcon className="text-white" />}
          {file.type !== FileUploadType.Link && <ActivityIcon className="w-5 text-white" />}
        </div>
        <div className="flex-grow">
          <FilePreview fileInfo={file}>
            <div className="min-w-full max-w-0 truncate font-medium text-black" data-testid="file-info-name" data-cy="file-info-file-name">
              {title}
            </div>
            <div className="text-color-3" data-testid="file-info-type" data-cy="file-info-file-type">
              {file.type === FileUploadType.Link ? StringUtils.truncate(file.url || '', 30) : FileUtils.getFileExtensionFromMimeType(file.type)}
            </div>
          </FilePreview>{' '}
        </div>
        <ContextMenu className="right-0 top-0 ml-4 h-6 w-6" items={menuItems} />
      </div>
    </div>
  );
};

export default FileInfoCard;
